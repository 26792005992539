import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import PanoramaIcon from "@mui/icons-material/Panorama";
import Fade from "@mui/material/Fade";
import noop from "lodash/noop";
import PropTypes from "prop-types";
import { useEffect, useMemo, useRef, useState } from "react";

import { scanResultDimensions } from "../constants/scan-result-dimensions";

import { PointsOfInterestFramesContainer } from "./PointsOfInterestFramesContainer";
import { calculatePositionFromDegrees } from "./calculate-position-degrees";
import { getClampedPosition } from "./get-clamped-position";
import {
  ArrowControl,
  Controls,
  Image,
  ImageError,
  PanoramaDragArea,
  useContainerStyles
} from "./styled-components";

const intervalPeriod = 30; // ms
const controlMovementStep = 50; // px
const controlDirections = Object.freeze({
  LEFT: "left",
  RIGHT: "right"
});

const PointsOfInterestPanoramaProps = {
  homePosition: PropTypes.number,
  onEmptyPanorama: PropTypes.func,
  onMoveHome: PropTypes.func,
  onMovePointOfInterest: PropTypes.func,
  onSelectHome: PropTypes.func,
  onSelectPointOfInterest: PropTypes.func,
  panoramaImageUrl: PropTypes.string,
  pointsOfInterest: PropTypes.array,
  readOnly: PropTypes.bool,
  selectedFrame: PropTypes.number,
  scaleFactor: PropTypes.number,
  touchedFrames: PropTypes.object
};

/**
 * This component renders a panorama image and allows users to navigate it horizontally
 * using drag gestures or arrow controls. The image height is calculated based on a
 * height scale, and it is clamped to the edges of the container element.
 *
 * It also displays points of interest on the panorama and triggers callback functions
 * when the user interacts with them.
 *
 * Navigation with arrow controls and mouse drag gestures is implemented using intervals,
 * which allows the user to hold down the button or drag without losing momentum. Touch drag
 * events do not use intervals.
 */
export const PointsOfInterestPanorama = ({
  homePosition = null,
  onEmptyPanorama = noop,
  onMoveHome = noop,
  onMovePointOfInterest = noop,
  onSelectHome = noop,
  onSelectPointOfInterest = noop,
  panoramaImageUrl = null,
  pointsOfInterest = [],
  readOnly = false,
  selectedFrame = 0,
  scaleFactor = 0.5,
  touchedFrames = {}
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [currentXPosition, setCurrentXPosition] = useState(0);
  const [panoramaXOffsetDelta, setPanoramaXOffsetDelta] = useState(null);
  const panoramaImageUrlRef = useRef();

  const [atLeftEdge, setAtLeftEdge] = useState(true);
  const [atRightEdge, setAtRightEdge] = useState(true);
  const [intervalIds, setIntervalIds] = useState([]);

  const containerClasses = useContainerStyles();
  const containerRef = useRef(null);
  const imageRef = useRef(null);
  const framesRef = useRef(null);

  const updatedScaleFactor = useMemo(() => {
    if (!imageLoaded) {
      return scaleFactor;
    }
    const { naturalHeight } = imageRef.current;
    return (scaleFactor * Math.max(window.innerHeight, 100)) / naturalHeight;
  }, [imageLoaded, scaleFactor]);

  // Smoothly transition when using the control buttons. It should not transition
  // when the `panoramaXOffsetDelta` is set since that means that the user is dragging
  // the PanoramaDragArea, which should be instant rather than eased.
  const transition = panoramaXOffsetDelta ? "none" : `all 0.3s ease-out`;

  /**
   * Effect: Update the rendered image height based on the `scaleFactor` and constrain
   * the container's width to the re-calculated image width. Check if the current
   * position is at the left/right edges of the image and update state accordingly.
   */
  useEffect(() => {
    if (imageLoaded) {
      const { naturalWidth: width, naturalHeight: height } = imageRef.current;
      const { width: containerWidth } =
        containerRef.current.getBoundingClientRect();

      const scaled = {
        height: height * updatedScaleFactor,
        width: width * updatedScaleFactor
      };

      const maxXOffset = -Number.parseInt(scaled.width - containerWidth);
      const isPartial = scaled.width > containerWidth;
      const isAtLeftEdge = currentXPosition === 0 || !isPartial;
      const isAtRightEdge = currentXPosition <= maxXOffset;

      setAtLeftEdge(isAtLeftEdge);
      setAtRightEdge(isAtRightEdge);

      imageRef.current.style.height = `${scaled.height}px`;
      containerRef.current.style.maxWidth = `${scaled.width}px`;
      framesRef.current.style.width = `${scaled.width}px`;
    }
  }, [imageLoaded, currentXPosition, updatedScaleFactor]);

  /**
   * Effect: Reset the image loading/error state when the image URL changes.
   */
  useEffect(() => {
    const imageChanged = panoramaImageUrl !== panoramaImageUrlRef.current;

    if (imageChanged) {
      setImageLoaded(false);
      setImageError(false);

      panoramaImageUrlRef.current = panoramaImageUrl;
    }
  }, [panoramaImageUrl]);

  /**
   * Effect: Clean up intervals on component unmount.
   */
  useEffect(() => {
    return () => {
      clearAllIntervals();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Effect: Auto-scroll to Point of Interest position upon selection.
   */
  useEffect(() => {
    const isFrameInRange =
      selectedFrame >= 0 && selectedFrame < pointsOfInterest.length;

    if (imageLoaded && isFrameInRange && pointsOfInterest.length) {
      const degrees = pointsOfInterest[selectedFrame];
      const { width: containerWidth } =
        containerRef.current.getBoundingClientRect();
      const { naturalWidth: imageWidth } = imageRef.current;

      const scaledImageWidth = imageWidth * updatedScaleFactor;
      const position = calculatePositionFromDegrees(scaledImageWidth, degrees);
      const centeredPosition = position - containerWidth / 2;
      const clampedPosition = getClampedPosition(
        0,
        -centeredPosition,
        imageRef,
        containerRef
      );

      setCurrentXPosition(clampedPosition);
    }
  }, [imageLoaded, pointsOfInterest, selectedFrame, updatedScaleFactor]);

  /**
   * updatePanoramaPosition
   */
  const updatePanoramaPosition = direction => () => {
    setCurrentXPosition(prevX => {
      const deltaX =
        direction === controlDirections.LEFT
          ? controlMovementStep
          : -controlMovementStep;

      return getClampedPosition(prevX, deltaX, imageRef, containerRef);
    });
  };

  /**
   * handlePanoramaMouseDown
   */
  const handlePanoramaMouseDown = (event, direction) => {
    event.preventDefault();
    setPanoramaXOffsetDelta(event.clientX);

    const intervalId = setInterval(() => {
      updatePanoramaPosition(direction);
    }, intervalPeriod);

    setIntervalIds(prevIds => [...prevIds, intervalId]);
  };

  /**
   * handlePanoramaMouseUp
   */
  const handlePanoramaMouseUp = () => {
    setPanoramaXOffsetDelta(null);
    clearAllIntervals();
  };

  /**
   * handlePanoramaMouseMove
   */
  const handlePanoramaMouseMove = event => {
    event.preventDefault();

    if (panoramaXOffsetDelta !== null) {
      const deltaX = event.clientX - panoramaXOffsetDelta;

      setCurrentXPosition(prevX =>
        getClampedPosition(prevX, deltaX, imageRef, containerRef)
      );
      setPanoramaXOffsetDelta(event.clientX);
    }
  };

  /**
   * handlePanoramaTouchStart
   */
  const handlePanoramaTouchStart = event => {
    event.preventDefault();
    const touch = event.touches[0];
    setPanoramaXOffsetDelta(touch.clientX);
  };

  /**
   * handlePanoramaTouchMove
   */
  const handlePanoramaTouchMove = event => {
    event.preventDefault();

    const touch = event.touches[0];
    const deltaX = touch.clientX - panoramaXOffsetDelta;

    setCurrentXPosition(prevX =>
      getClampedPosition(prevX, deltaX, imageRef, containerRef)
    );
    setPanoramaXOffsetDelta(touch.clientX);
  };

  /**
   * handlePanoramaTouchEnd
   */
  const handlePanoramaTouchEnd = () => setPanoramaXOffsetDelta(null);

  /**
   * clearAllIntervals
   */
  const clearAllIntervals = () => intervalIds.forEach(clearInterval);

  /**
   * handleArrowControlMouseDown
   */
  const handleArrowControlMouseDown = direction => () => {
    const intervalId = setInterval(
      updatePanoramaPosition(direction),
      intervalPeriod
    );

    setIntervalIds(prevIds => [...prevIds, intervalId]);
  };

  /**
   * handleArrowControlMouseUp
   */
  const handleArrowControlMouseUp = () => clearAllIntervals();

  /**
   * handleImageLoad
   */
  const handleImageLoad = () => {
    const { naturalWidth: width } = imageRef.current;
    const { width: containerWidth } =
      containerRef.current.getBoundingClientRect();

    // Once the image is loaded, scroll to the center position if needed.
    //
    // 1. Start at the image center position
    // 2. Center inside container
    // 3. Center frame
    const imageExceedsContainer = width * updatedScaleFactor > containerWidth;
    if (imageExceedsContainer) {
      setCurrentXPosition(
        -Math.floor((width * updatedScaleFactor) / 2) + containerWidth / 2
      );
    }

    setImageError(false);
    setImageLoaded(true);
  };

  /**
   * handleImageError
   */
  const handleImageError = () => {
    setImageError(true);
    onEmptyPanorama();
  };

  if (imageError) {
    return (
      <div className={containerClasses.paper}>
        {imageError && (
          <ImageError
            style={{ height: scanResultDimensions.HEIGHT * updatedScaleFactor }}
          >
            <PanoramaIcon />
            Panorama not available
          </ImageError>
        )}
      </div>
    );
  }

  return (
    <div ref={containerRef} className={containerClasses.paper}>
      <Image
        alt="Kuva Camera panorama image"
        ref={imageRef}
        src={panoramaImageUrl}
        style={{
          height: scanResultDimensions.HEIGHT * updatedScaleFactor,
          transform: `translateX(${currentXPosition}px)`,
          transition
        }}
        onError={handleImageError}
        onLoad={handleImageLoad}
        imageLoaded={imageLoaded}
      />

      <Controls>
        <Fade in={!atLeftEdge} timeout={350}>
          <ArrowControl
            onMouseDown={handleArrowControlMouseDown(controlDirections.LEFT)}
            onMouseLeave={handleArrowControlMouseUp}
            onMouseUp={handleArrowControlMouseUp}
            onTouchEnd={handleArrowControlMouseUp}
            onTouchStart={handleArrowControlMouseDown(controlDirections.LEFT)}
          >
            <ChevronLeftIcon />
          </ArrowControl>
        </Fade>

        <PanoramaDragArea
          onMouseDown={handlePanoramaMouseDown}
          onMouseMove={handlePanoramaMouseMove}
          onMouseUp={handlePanoramaMouseUp}
          onMouseLeave={handlePanoramaMouseUp}
          onTouchStart={handlePanoramaTouchStart}
          onTouchEnd={handlePanoramaTouchEnd}
          onTouchMove={handlePanoramaTouchMove}
        />

        <Fade in={!atRightEdge} timeout={350}>
          <ArrowControl
            onMouseDown={handleArrowControlMouseDown(controlDirections.RIGHT)}
            onMouseLeave={handleArrowControlMouseUp}
            onMouseUp={handleArrowControlMouseUp}
            onTouchEnd={handleArrowControlMouseUp}
            onTouchStart={handleArrowControlMouseDown(controlDirections.RIGHT)}
            invertGradient
          >
            <ChevronRightIcon />
          </ArrowControl>
        </Fade>
      </Controls>

      {imageLoaded && (
        <PointsOfInterestFramesContainer
          ref={framesRef}
          homePosition={homePosition}
          onMoveHome={onMoveHome}
          onMovePointOfInterest={onMovePointOfInterest}
          onSelectHome={onSelectHome}
          onSelectPointOfInterest={onSelectPointOfInterest}
          pointsOfInterest={pointsOfInterest}
          readOnly={readOnly}
          selectedFrame={selectedFrame}
          scaleFactor={updatedScaleFactor}
          style={{
            transform: `translateX(${currentXPosition}px)`,
            transition
          }}
          touchedFrames={touchedFrames}
        />
      )}
    </div>
  );
};

PointsOfInterestPanorama.propTypes = PointsOfInterestPanoramaProps;
