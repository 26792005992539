import { createAsyncThunk } from "@reduxjs/toolkit";

import AlarmAPI from "~/apis/AlarmAPI";

/**
 * @param {string} orgId
 */

export const getStreamAlarmDevices = createAsyncThunk(
  "alarms/getStreamAlarmDevices",
  async orgId => {
    const { data } = await AlarmAPI.getStreamAlarmDevices(orgId);
    return data;
  }
);
