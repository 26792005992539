import { useCallback } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { get } from "~/selectors/AlarmSelector";
import { AlarmsActions } from "~/store/slices/alarms";

export const useGetLatestAlarms = ({ orgId, sortSetting }) => {
  const dispatch = useDispatch();
  const { latestAlarms, loading, totalLatest } = useSelector(get, shallowEqual);

  const getLatestAlarms = useCallback(
    async ({ streamIds, deviceIds, ...body }) => {
      if (orgId) {
        const sortString = sortSetting
          ? `${
              sortSetting.columnToSort
            } ${sortSetting.sortDirection.toUpperCase()}`
          : undefined;
        dispatch(
          AlarmsActions.getLatestAlarms({
            streamIds,
            deviceIds,
            sort: sortString,
            ...body,
            orgId
          })
        );
      }
    },
    [dispatch, orgId, sortSetting]
  );

  return {
    getLatestAlarms,
    loading,
    total: totalLatest,
    latestAlarms
  };
};
