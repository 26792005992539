import { useCallback, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { useOrganization } from "~/hooks";
import {
  getAlarmState,
  getActiveAlarm,
  selectStreamAlarmDevices
} from "~/selectors/AlarmSelector";
import { AlarmsActions } from "~/store/slices/alarms";
import { groupByDate, sortDates } from "~/utils/dateTimeUtils";

import { useSettings } from "./use-settings";

export const useGetAlarms = () => {
  const { deviceId, orgId } = useParams();
  const { selectedOrg } = useOrganization();
  const selectedOrgId = orgId || selectedOrg?.id;

  const streamAlarmDevices = useSelector(
    selectStreamAlarmDevices,
    shallowEqual
  );
  const { alarms, loading, total } = useSelector(getAlarmState, shallowEqual);
  const activeAlarm = useSelector(getActiveAlarm, shallowEqual);
  const isAlarmDeviceUnassigned = activeAlarm?.isUnassigned;
  const { timeZone } = useSettings();
  const { streams } = useSelector(state => state.cameraState);
  const dispatch = useDispatch();
  const disabled = alarms.length >= total;
  const streamId = streams.byDeviceId[deviceId]?.id;

  const alarmsByDay = useMemo(() => {
    const groupedAlarmsByDay = groupByDate(alarms, timeZone);

    return sortDates(Array.from(groupedAlarmsByDay), "desc");
  }, [alarms, timeZone]);

  const getAlarms = useCallback(
    async ({ page, pagesize, ...params }) => {
      await dispatch(
        AlarmsActions.getAlarmByPagination({
          page,
          pagesize,
          ...(streamId ? { streamId } : { deviceId }),
          ...params
        })
      );
    },
    [deviceId, dispatch, streamId]
  );

  const getMoreAlarms = useCallback(
    async ({ page, pagesize, ...params }) => {
      await dispatch(
        AlarmsActions.getMoreAlarms({
          page,
          pagesize,
          ...(streamId ? { streamId } : { deviceId }),
          ...params
        })
      );
    },
    [deviceId, dispatch, streamId]
  );

  const getAlarmsFromDay = useCallback(
    async props => {
      await dispatch(AlarmsActions.clearAlarms());
      await getAlarms(props);
    },
    [dispatch, getAlarms]
  );

  const getStreamAlarmDevices = (orgId = selectedOrgId) =>
    dispatch(AlarmsActions.getStreamAlarmDevices(orgId));

  return {
    loading,
    getStreamAlarmDevices,
    streamAlarmDevices,
    alarms,
    getAlarms,
    alarmsByDay,
    disabled,
    total,
    getAlarmsFromDay,
    getMoreAlarms,
    activeAlarm,
    isAlarmDeviceUnassigned
  };
};
