import { shallowEqual, useSelector } from "react-redux";

import {
  getNormalizeLatestAlarm,
  selectIsLoadingCameraEvents
} from "~/selectors/CameraSelector";

export const useCameraWithLatestEvent = () => {
  const latestAlarms = useSelector(getNormalizeLatestAlarm, shallowEqual);
  const isLoadingCameraEvents = useSelector(selectIsLoadingCameraEvents);

  return {
    latestAlarms,
    isLoadingCameraEvents
  };
};
