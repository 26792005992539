import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { Paper } from "@mui/material";
import { isEmpty } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useDispatch } from "react-redux";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";

import {
  SnackbarNotification,
  makeStyles,
  withTheme
} from "@kuva/ui-components";
import { useStartSignalR } from "@kuva/ui-helpers";

import { setCameraReportedAttributes } from "~/actions/cameraActions";
import { flags } from "~/constants/feature-flags";
import { FrameSettingsProvider } from "~/contextProviders/FrameSettingsContextProvider";
import { useLogout, useOrganization, useSettings, useUserInfo } from "~/hooks";
import {
  CameraPageDetailsView,
  CameraPageGeneralView
} from "~/pages/CameraPage";
import CameraViewNew from "~/pages/CameraViewNew/Loadable";
import EventsView from "~/pages/EventsPage/Loadable";
import StreamIDManagement from "~/pages/StreamIDManagement/Loadable";
import UserAccountSettings from "~/pages/UserAccountSettings/Loadable";
import Dash from "~/pages/dashboard/Loadable";
import OrganizationSettings from "~/pages/organizationSettings/Loadable";
import Organizations from "~/pages/organizations";
import { ApplicationSettingsActions } from "~/store/slices/application-settings";
import { CameraActions } from "~/store/slices/cameras";

import UserNotification from "./components/UserNotification";
import NavBar from "./components/header/NavBar";

const useStyles = makeStyles()(() => ({
  root: {
    background: "#222222",
    height: "calc(100vh - 80px)",
    overflow: "hidden"
  },
  mainContent: {
    overflow: "auto",
    height: "100%"
  }
}));

const minInSec = 60;

const MainContent = () => {
  const { classes } = useStyles();
  const { logout } = useLogout();
  const { userAccountSettings, showArchived } = useSettings();
  const { user, isSuperuser } = useUserInfo();
  const dispatch = useDispatch();
  const {
    setSelectedOrg,
    normalizedOrgs,
    selectedOrg,
    organizations,
    nonOrgRoutes
  } = useOrganization();
  const matchRoot = useRouteMatch({
    path: "/",
    exact: true
  });
  const matchOrg = useRouteMatch({
    path: "/:orgId",
    exact: true
  });
  const matchDevice = useRouteMatch({
    path: "/:orgId/:deviceId"
  });
  const orgIdParam = matchOrg?.params?.orgId || matchDevice?.params?.orgId;
  const selectedOrgId = orgIdParam || selectedOrg?.id;

  const [remainingTime, setRemainingTime] = useState(Number.MAX_SAFE_INTEGER);
  const history = useHistory();
  const userId = user?.sub;

  const organizationFeatureIsOn = useFeatureIsOn(flags.ORG_SETTINGS_TAB);
  const isLessThanAMinute = remainingTime < minInSec;
  const topLevelOrgId = Object.values(normalizedOrgs)[0].id;
  const isNonOrgRoute = nonOrgRoutes.includes(orgIdParam);

  const handleOnAction = () => {
    if (isLessThanAMinute) {
      setRemainingTime(Number.MAX_SAFE_INTEGER);
    }
  };

  const { getRemainingTime } = useIdleTimer({
    timeout: process.env.REACT_APP_TIMEOUT,
    onIdle: logout,
    onActive: () => {},
    onAction: handleOnAction,
    debounce: 500
  });

  useEffect(() => {
    /* RUN ONLY ONCE - Don't change dependecy ^_^  */
    /* Set default organization */
    const defaultOrg = selectedOrg?.id || topLevelOrgId;
    const orgId = defaultOrg || orgIdParam;
    if (isNonOrgRoute) return;

    // If the current route matches the organization route, set the organization state.
    if (matchOrg) {
      setSelectedOrg(orgId);
    }

    // If the current route matches the root route, set the organization state and redirect
    // to the dashboard route.
    if (matchRoot) {
      setSelectedOrg(orgId);
      history.push(`/${orgId}`);
    }

    // If not on the root route and the selected org ID differs from the orgIdParam,
    // update the org state to reflect the URL parameter.
    if (!matchRoot && selectedOrg?.id !== orgIdParam) {
      setSelectedOrg(orgIdParam);
    }

    /* set default settings */
    if (isEmpty(userAccountSettings))
      dispatch(
        ApplicationSettingsActions.setDefaultUserAccountSettings({
          userId
        })
      );
  }, []);

  useEffect(() => {
    // TODO Jan: check if this is still in use
    const interval = setInterval(() => {
      const seconds = Math.round(getRemainingTime() / 1000);

      if (seconds < 60) {
        setRemainingTime(seconds);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [remainingTime, getRemainingTime]);

  const setReportedAttributes = useCallback(
    attr => dispatch(setCameraReportedAttributes(attr)),
    [dispatch]
  );

  useEffect(() => {
    if (selectedOrgId && !isNonOrgRoute) {
      dispatch(
        CameraActions.getCameras({ orgId: selectedOrgId, showArchived })
      );

      dispatch(
        CameraActions.getStreams({ orgId: selectedOrgId, showArchived })
      );
    }
  }, [selectedOrgId, showArchived, organizations, isNonOrgRoute, dispatch]);

  // TODO: remove this after apim is fully implemented and just call useStartSignalR(setReportedAttributes);
  useStartSignalR(setReportedAttributes);

  return (
    <>
      <FrameSettingsProvider>
        <NavBar />
        <Paper className={classes.root}>
          <div className={classes.mainContent}>
            <Switch>
              <Route
                path="/:orgId/events"
                exact
                render={() => <EventsView />}
              />
              <Route
                exact
                path="/:orgId/cameras/details-view"
                render={() => <CameraPageDetailsView />}
              />
              <Route
                exact
                path="/:orgId/cameras/general-view"
                render={() => <CameraPageGeneralView />}
              />
              {organizationFeatureIsOn && (
                <Route
                  path="/organization-settings/:orgId/:tab?"
                  render={() => <OrganizationSettings />}
                />
              )}
              {isSuperuser && (
                <Route
                  path="/organizations-administration"
                  render={() => <Organizations />}
                />
              )}
              {isSuperuser && (
                <Route
                  path="/:orgId/stream-id-management"
                  exact
                  render={() => <StreamIDManagement />}
                />
              )}
              <Route
                path="/account-settings/:tab"
                exact
                render={() => <UserAccountSettings />}
              />

              <Route
                path="/:orgId/:deviceId/:alarmId?/:streamId?"
                render={() => <CameraViewNew />}
              />
              <Route exact path="/:orgId" render={() => <Dash />} />
            </Switch>
          </div>
          <UserNotification />
        </Paper>
        {isLessThanAMinute && (
          <SnackbarNotification
            message={`Logging you out due to inactivity in ${remainingTime} seconds`}
          />
        )}
      </FrameSettingsProvider>
    </>
  );
};

MainContent.propTypes = {};

export default withTheme(MainContent);
