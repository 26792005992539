import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SettingsIcon from "@mui/icons-material/Settings";
import { Menu } from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";

import { direction } from "./ImagePlayer";
import { ImagePlayerButton, MenuItem, usePlayerMenuStyles } from "./styles";

const anchorOrigin = {
  vertical: "top",
  horizontal: "right"
};
const transformOrigin = {
  vertical: "top",
  horizontal: "left"
};
const anchors = { anchorOrigin, transformOrigin };

export const SettingsMenuButton = ({
  disablePlayback,
  playbackSpeed,
  setPlaybackSpeed,
  cameraView,
  setCameraView,
  setViewType,
  cameraViews,
  playbackDirection,
  setPlaybackDirection
}) => {
  const {
    menuTitle: menuTitleClasses,
    menuItem: menuItemClasses,
    ...menuClasses
  } = usePlayerMenuStyles();

  const [settingsMenuAnchor, setSettingsMenuAnchor] = useState(null);
  const [playbackSpeedMenuAnchor, setPlaybackSpeedMenuAnchor] = useState(null);
  const [playbackDirectionMenuAnchor, setPlaybackDirectionMenuAnchor] =
    useState(null);
  const [cameraViewMenuAnchor, setCameraViewMenuAnchor] = useState(null);

  const handleSettingsMenuClick = event => {
    event.stopPropagation();
    setSettingsMenuAnchor(event.currentTarget);
  };

  const handleSettingsMenuClose = () => {
    setSettingsMenuAnchor(null);
    setPlaybackSpeedMenuAnchor(null);
    setPlaybackDirectionMenuAnchor(null);
    setCameraViewMenuAnchor(null);
  };

  const handlePlaybackDirectionMenuClick = event => {
    event.stopPropagation();
    setPlaybackDirectionMenuAnchor(event.currentTarget);
  };

  const handlePlaybackDirectionMenuClose = () => {
    setPlaybackDirectionMenuAnchor(null);
  };

  const handlePlaybackSpeedMenuClick = event => {
    event.stopPropagation();
    setPlaybackSpeedMenuAnchor(event.currentTarget);
  };

  const handlePlaybackSpeedMenuClose = () => {
    setPlaybackSpeedMenuAnchor(null);
  };

  const handleChangePlaybackSpeed = speed => event => {
    event.stopPropagation();
    setPlaybackSpeed(speed);
    handlePlaybackSpeedMenuClose();
  };

  const handleChangePlaybackDirection = newDirection => event => {
    event.stopPropagation();
    setPlaybackDirection(newDirection);
    localStorage.setItem("playbackDirection", newDirection);

    handlePlaybackDirectionMenuClose();
  };

  const handleCameraViewMenuClick = event => {
    event.stopPropagation();
    setCameraViewMenuAnchor(event.currentTarget);
  };

  const handleCameraViewMenuClose = () => {
    setCameraViewMenuAnchor(null);
  };

  const handleChangeCameraView = view => event => {
    event.stopPropagation();
    setCameraView(view);
    setViewType(view);
    handleCameraViewMenuClose();
  };

  return (
    <>
      <ImagePlayerButton
        size="small"
        onClick={handleSettingsMenuClick}
        data-testid="settings-button"
      >
        <SettingsIcon />
      </ImagePlayerButton>
      <Menu
        classes={menuClasses}
        open={Boolean(settingsMenuAnchor)}
        anchorEl={settingsMenuAnchor}
        onClose={handleSettingsMenuClose}
        {...anchors}
      >
        <MenuItem
          onClick={handlePlaybackSpeedMenuClick}
          disabled={disablePlayback}
          className={menuItemClasses}
        >
          Playback speed
          <span>
            {playbackSpeed}x <ChevronRightIcon className="icon" />
          </span>
        </MenuItem>
        <MenuItem
          onClick={handlePlaybackDirectionMenuClick}
          disabled={disablePlayback}
          className={menuItemClasses}
        >
          Playback direction
          <span>
            {playbackDirection === direction.forward ? "Forward" : "Backward"}
            <ChevronRightIcon className="icon" />
          </span>
        </MenuItem>
        {setViewType && (
          <MenuItem
            onClick={handleCameraViewMenuClick}
            className={menuItemClasses}
          >
            Camera view
            <span>
              {cameraView} <ChevronRightIcon className="icon" />
            </span>
          </MenuItem>
        )}
        <Menu
          classes={menuClasses}
          open={Boolean(playbackDirectionMenuAnchor)}
          anchorEl={playbackDirectionMenuAnchor}
          onClose={handlePlaybackDirectionMenuClose}
          {...anchors}
        >
          <div className={menuTitleClasses}>Playback direction</div>
          <MenuItem
            onClick={handleChangePlaybackDirection(direction.backwards)}
            disabled={playbackDirection === direction.backwards}
          >
            Backward
          </MenuItem>
          <MenuItem
            onClick={handleChangePlaybackDirection(direction.forward)}
            disabled={playbackDirection === direction.forward}
          >
            Forward
          </MenuItem>
        </Menu>
        <Menu
          classes={menuClasses}
          open={Boolean(playbackSpeedMenuAnchor)}
          anchorEl={playbackSpeedMenuAnchor}
          onClose={handlePlaybackSpeedMenuClose}
          {...anchors}
        >
          <div className={menuTitleClasses}>Playback speed</div>
          <MenuItem
            onClick={handleChangePlaybackSpeed(1)}
            disabled={playbackSpeed === 1}
          >
            1x
          </MenuItem>
          <MenuItem
            onClick={handleChangePlaybackSpeed(2)}
            disabled={playbackSpeed === 2}
          >
            2x
          </MenuItem>
          <MenuItem
            onClick={handleChangePlaybackSpeed(3)}
            disabled={playbackSpeed === 3}
          >
            3x
          </MenuItem>
        </Menu>

        <Menu
          classes={menuClasses}
          open={Boolean(cameraViewMenuAnchor)}
          anchorEl={cameraViewMenuAnchor}
          onClose={handleCameraViewMenuClose}
          {...anchors}
        >
          <div className={menuTitleClasses}>Camera view</div>
          <MenuItem
            onClick={handleChangeCameraView(cameraViews.rgb)}
            disabled={cameraView === cameraViews.rgb}
          >
            {cameraViews.rgb}
          </MenuItem>
          <MenuItem
            onClick={handleChangeCameraView(cameraViews.swir)}
            disabled={cameraView === cameraViews.swir}
          >
            {cameraViews.swir}
          </MenuItem>
          <MenuItem
            onClick={handleChangeCameraView(cameraViews.both)}
            disabled={cameraView === cameraViews.both}
          >
            {cameraViews.both}
          </MenuItem>
        </Menu>
      </Menu>
    </>
  );
};

SettingsMenuButton.propTypes = {
  disablePlayback: PropTypes.bool,
  playbackSpeed: PropTypes.number,
  setPlaybackSpeed: PropTypes.func,
  cameraView: PropTypes.string,
  setCameraView: PropTypes.func,
  setViewType: PropTypes.func,
  cameraViews: PropTypes.object,
  playbackDirection: PropTypes.number,
  setPlaybackDirection: PropTypes.func
};
