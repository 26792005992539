import HomeIcon from "@mui/icons-material/Home";
import Fade from "@mui/material/Fade";
import noop from "lodash/noop";
import PropTypes from "prop-types";
import { useCallback, useEffect, useRef, useState } from "react";
import { useHoverDirty } from "react-use";

import { UnsavedStateBadge } from "../styled-components";

import {
  calculateDegreesFromPosition,
  calculatePositionFromDegrees
} from "./calculate-position-degrees";
import {
  Frame,
  FrameCenterHighlightHelper,
  FrameGridHelper,
  FrameHelpersOverlay,
  FrameIdentifierDecoration
} from "./styled-components";

const PointsOfInterestFramePropTypes = {
  id: PropTypes.number,
  active: PropTypes.bool,
  home: PropTypes.bool,
  offsetDegrees: PropTypes.number,
  onMove: PropTypes.func,
  readOnly: PropTypes.bool,
  scaleFactor: PropTypes.number,
  forwardedRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any })
  ]),
  isTouched: PropTypes.bool
};

/**
 * Renders a frame representing a Point of Interest and handles all interactivity
 * for that frame. Allows click-dragging active frames to move their position and
 * executes a callback, providing updated values in both pixel and degree units.
 */
export const PointsOfInterestFrame = ({
  id,
  active = false,
  home = false,
  offsetDegrees = null,
  scaleFactor,
  forwardedRef,
  onMove = noop,
  isTouched = false,
  ...props
}) => {
  const [parentDimensions, setParentDimensions] = useState();
  const frameRef = useRef(null);
  const draggedFrameRef = useRef(null);
  const hovered = useHoverDirty(frameRef);

  const [dragging, setDragging] = useState(false);
  const [startX, setStartX] = useState(null);
  const [currentX, setCurrentX] = useState(null);
  const [startOffsetDegrees, setStartOffsetDegrees] = useState(null);
  const [currentOffsetDegrees, setCurrentOffsetDegrees] = useState(null);

  const parentWidth = parentDimensions?.width;

  const [position, setPosition] = useState(0);

  const camWideView = 45;
  const frameWidth45 = (parentDimensions?.width / 360) * camWideView;
  const left = position - frameWidth45 / 2;

  /**
   * Effect: Update the frame's position when it's dragged or when it's
   * updated by the parent.
   */
  useEffect(() => {
    const degrees = parseInt(offsetDegrees);
    if (!isNaN(degrees)) {
      const updatedPosition = dragging
        ? calculatePositionFromDegrees(parentWidth, currentOffsetDegrees)
        : calculatePositionFromDegrees(parentWidth, degrees);

      if (!isNaN(updatedPosition)) {
        setPosition(updatedPosition);
        setStartOffsetDegrees(degrees);
      }
    }
  }, [
    currentOffsetDegrees,
    draggedFrameRef,
    dragging,
    frameRef,
    offsetDegrees,
    parentWidth
  ]);

  /**
   * Effect: Sync the parent width value after the parent element adjusts
   * its dimensions.
   */
  useEffect(() => {
    if (!forwardedRef?.current) return;

    const observer = new ResizeObserver(entries => {
      const { height, width } = entries[0].contentRect;
      setParentDimensions({ width, height });
    });

    observer.observe(forwardedRef.current);

    return () => {
      observer.disconnect();
    };
  }, [forwardedRef, scaleFactor]);

  /**
   * handleMouseDown
   */
  const handleMouseDown = event => {
    if (!active || props.readOnly) return;

    setDragging(true);
    setStartX(event.clientX);
    setStartOffsetDegrees(parseInt(offsetDegrees));
    setCurrentOffsetDegrees(parseInt(offsetDegrees));
    draggedFrameRef.current = frameRef.current;
  };

  /**
   * handleMouseMove
   */
  const handleMouseMove = useCallback(
    event => {
      if (!dragging || !draggedFrameRef.current) return;

      const deltaX = event.clientX - startX;
      const deltaDegrees = calculateDegreesFromPosition(parentWidth, deltaX);

      setCurrentOffsetDegrees(startOffsetDegrees + deltaDegrees);
      setCurrentX(event.clientX);
    },
    [dragging, draggedFrameRef, parentWidth, startX, startOffsetDegrees]
  );

  /**
   * handleMouseUp
   */
  const handleMouseUp = useCallback(
    event => {
      if (!dragging || !draggedFrameRef.current) return;

      const deltaX = currentX - startX;
      const deltaDegrees = calculateDegreesFromPosition(parentWidth, deltaX);
      const newOffsetDegrees = startOffsetDegrees + deltaDegrees;
      const newOffsetPixels = calculatePositionFromDegrees(
        parentWidth,
        newOffsetDegrees
      );

      setDragging(false);
      setStartX(null);
      setCurrentX(null);
      setCurrentOffsetDegrees(null);

      if (currentX) {
        onMove(newOffsetDegrees, newOffsetPixels, event);
      }
    },
    [
      dragging,
      draggedFrameRef,
      parentWidth,
      startX,
      startOffsetDegrees,
      currentX,
      onMove
    ]
  );

  /**
   * Effect: Bind mouse events directly in the document so that interactivity
   * is not lost when the mouse leaves the drag area.
   */
  useEffect(() => {
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [dragging, handleMouseMove, handleMouseUp]);

  return (
    <Frame
      {...props}
      ref={frameRef}
      onMouseDown={handleMouseDown}
      style={{ left, width: frameWidth45 }}
      active={active}
      data-testid="poi-frame"
    >
      <FrameHelpersOverlay readOnly={props.readOnly}>
        <Fade in={active} timeout={250}>
          <FrameGridHelper />
        </Fade>
        <Fade in={hovered} timeout={250}>
          <FrameCenterHighlightHelper active={active} />
        </Fade>
      </FrameHelpersOverlay>

      <FrameIdentifierDecoration
        readOnly={props.readOnly}
        active={active}
        home={home}
      >
        {home ? <HomeIcon /> : id}
      </FrameIdentifierDecoration>
      <Fade in={isTouched} timeout={300}>
        <UnsavedStateBadge />
      </Fade>
    </Frame>
  );
};

PointsOfInterestFrame.propTypes = PointsOfInterestFramePropTypes;
