import { endOfDay, startOfDay } from "date-fns";
import { useState } from "react";

import { useSnackbar } from "@kuva/ui-components";

import AlarmAPI from "~/apis/AlarmAPI";
import { createCsvLink, downloadCSV } from "~/components/Table/TableMenu/utils";
import { EXPORT_TIME_FORMATS } from "~/constants/csv-export";

export const useCSVDownload = () => {
  const [timeFormat, setTimeFormat] = useState(EXPORT_TIME_FORMATS.EASY_READ);
  const { showSnackbar } = useSnackbar();

  const downloadAsCSV = ({ rows, columns, sortSetting, filename }) => {
    const downloadSuccess = downloadCSV(
      { rows, columns, sortSetting, timeFormat },
      filename
    );

    downloadSuccess
      ? showSnackbar("CSV download was successful.")
      : showSnackbar("Failed to download CSV file.", {
          variant: "error"
        });
  };

  const downloadviaAPI = async ({
    startDate,
    endDate,
    orgId,
    userSettings,
    filename,
    timezone,
    limit = 1000,
    ...params
  }) => {
    try {
      const { data } = await AlarmAPI.download({
        start: startOfDay(startDate),
        end: endOfDay(endDate),
        orgId,
        units: userSettings,
        timeFormat:
          timeFormat === EXPORT_TIME_FORMATS.EASY_READ
            ? "MMMM DD, YYYY, h:mm:ss a"
            : null,
        timezone,
        limit,
        ...params
      });
      createCsvLink(data, filename);
      showSnackbar("CSV downloaded successfully");
    } catch (error) {
      showSnackbar(`Alarms API: ${error.message.toString()}`, {
        variant: "error"
      });
    }
  };

  return { timeFormat, setTimeFormat, downloadAsCSV, downloadviaAPI };
};
