import { differenceInMinutes } from "date-fns";
import { isNil } from "lodash";

import { filterValidFrames, getWindMetrics } from "@kuva/calculations";

import { useQuantification } from "~/hooks";
import { formatPoiName } from "~/utils/cameraUtils";

export const useParseAlarm = (alarm = {}, poiNames) => {
  const {
    id,
    streamId,
    deviceId,
    deviceName,
    poiOrientation,
    quantification,
    thumbnail,
    numberOfFrames,
    source,
    humanActivity,
    tags,
    avgTshirtSize,
    start,
    end,
    frames,
    distanceSegmentName = "N/A"
  } = alarm;

  const poi = poiNames[`${deviceId}-${poiOrientation}`];
  const avgRate = quantification?.avg;
  /* start Backwards compatible with old alarm structure */
  const duration = isNil(alarm.duration)
    ? differenceInMinutes(new Date(end), new Date(start)) + 1
    : alarm.duration;
  let avgWindSpeed = alarm.avgWindSpeed;
  let avgWindDirection = alarm.avgWindDirection;

  if (isNil(alarm.avgWindSpeed) && isNil(alarm.avgWindDirection)) {
    const validFrames = filterValidFrames(frames);
    const hasValidFrames = validFrames.length > 0;

    if (hasValidFrames) {
      const { averageWindSpeed, averageWindDirection } =
        getWindMetrics(validFrames);

      avgWindSpeed = averageWindSpeed;
      avgWindDirection = averageWindDirection;
    }
  }
  /* end backwards compatible with old alarm structure */

  const {
    leakAvg,
    showLeakRate,
    reversedFrames,
    algoResult,
    avg,
    volume,
    data
  } = useQuantification(alarm);

  return {
    avgTshirtSize,
    showLeakRate,
    duration,
    leakAvg,
    avgRate,
    volume,
    id,
    streamId,
    deviceId,
    deviceName,
    thumbnail,
    numberOfFrames,
    poi: formatPoiName(poiOrientation, poi?.name),
    source,
    humanActivity: humanActivity === "yes" ? true : false,
    tags,
    avgWindSpeed,
    avgWindDirection,
    reversedFrames,
    algoResult,
    avg,
    data,
    distanceSegmentName
  };
};
