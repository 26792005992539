import { createSlice } from "@reduxjs/toolkit";

import { sortByDate } from "~/utils/dateTimeUtils";

import { getStreamAlarmDevices } from "./thunks/get-stream-alarm-devices";
import { getAlarmById } from "./thunks/fetch-alarm-by-id";
import { getAlarmsByMonth } from "./thunks/fetch-alarm-by-month";
import { getAlarmByPagination } from "./thunks/fetch-alarms-by-page";
import { getAlarmsCount } from "./thunks/fetch-latest-alarms-count-per-device";
import { getMoreAlarms } from "./thunks/fetch-more-alarms";
import { getActiveAlarm } from "./thunks/get-active-alarm";
import { getEmailNotificationSubscribersByOrg } from "./thunks/get-email-notification-subscribers-by-org";
import { getLatestAlarms } from "./thunks/get-latest-alarms";
import { updateEmailNotification } from "./thunks/patch-email-notification-subscribers-by-org";
const initialState = {
  streamAlarmDevices: [],
  isLoadingStreamAlarmDevices: false,
  activeAlarm: {
    loading: false,
    error: false,
    alarm: {}
  },
  latestAlarms: [],
  alarms: [],
  alarmsByMonth: {},
  alarmsCount: {},
  loading: false,
  error: { error: false },
  total: 0,
  totalLatest: 0,
  notifiedUsers: { recipients: [] },
  alarmParams: {
    page: 1
  }
};

const slice = createSlice({
  name: "alarms",
  initialState,
  reducers: {
    clearAlarms: state => {
      state.alarms = [];
      state.total = 0;
    },
    clearAllAlarms: state => {
      state.alarms = [];
      state.total = 0;
      state.alarmsByMonth = {};
    },
    clearActiveAlarm: state => {
      state.activeAlarm = initialState.activeAlarm;
    },
    clearNotifiedUsers: state => {
      state.notifiedUsers = initialState.notifiedUsers;
    },
    setMonthlyToAlarms: (state, action) => {
      state.alarms = state.alarmsByMonth[action.payload];
    },
    setAlarmParams: (state, action) => {
      state.alarmParams = { ...state.alarmParams, ...action.payload };
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getStreamAlarmDevices.pending, state => {
        state.isLoadingStreamAlarmDevices = true;
      })

      .addCase(getStreamAlarmDevices.rejected, state => {
        state.isLoadingStreamAlarmDevices = false;
        state.error = { error: true };
      })

      .addCase(getStreamAlarmDevices.fulfilled, (state, action) => {
        const { payload } = action;
        state.streamAlarmDevices = payload;
        state.isLoadingStreamAlarmDevices = false;
      })

      .addCase(getActiveAlarm.pending, state => {
        state.activeAlarm.loading = true;
      })

      .addCase(getActiveAlarm.rejected, state => {
        state.activeAlarm.loading = false;
        state.activeAlarm.error = true;
      })

      .addCase(getActiveAlarm.fulfilled, (state, action) => {
        const { payload } = action;
        state.activeAlarm.alarm = payload;
        state.activeAlarm.loading = false;
      })

      .addCase(getEmailNotificationSubscribersByOrg.pending, state => {
        state.loading = true;
      })

      .addCase(getEmailNotificationSubscribersByOrg.rejected, state => {
        state.loading = false;
        state.error.error = true;
      })

      .addCase(
        getEmailNotificationSubscribersByOrg.fulfilled,
        (state, action) => {
          const { payload } = action;
          state.notifiedUsers = payload ? payload : state.notifiedUsers;
          state.loading = false;
        }
      )

      .addCase(updateEmailNotification.pending, state => {
        state.loading = true;
      })

      .addCase(updateEmailNotification.rejected, state => {
        state.loading = false;
        state.error.error = true;
      })

      .addCase(updateEmailNotification.fulfilled, (state, action) => {
        const { payload } = action;
        state.notifiedUsers = payload;
        state.loading = false;
      })

      .addCase(getLatestAlarms.pending, state => {
        state.loading = true;
      })

      .addCase(getLatestAlarms.fulfilled, (state, action) => {
        state.latestAlarms = sortByDate([...action.payload.data]);
        state.totalLatest = action.payload.total;
        state.loading = false;
      })

      .addCase(getLatestAlarms.rejected, (state, action) => {
        const error = {
          error: true,
          errorOn: action?.payload?.errorOn
        };

        state.loading = false;
        state.error = error;
      })
      .addCase(getAlarmsByMonth.pending, state => {
        state.loading = true;
      })

      .addCase(getAlarmsByMonth.fulfilled, (state, action) => {
        const { data, year, month } = action.payload;
        state.alarmsByMonth[`${year}-${month}`] = data;
        state.loading = false;
      })

      .addCase(getAlarmsByMonth.rejected, (state, action) => {
        const error = {
          error: true,
          errorOn: action?.payload?.errorOn
        };

        state.loading = false;
        state.error = error;
      })
      .addCase(getAlarmByPagination.pending, state => {
        state.loading = true;
      })

      .addCase(getAlarmByPagination.fulfilled, (state, action) => {
        const { data, ...pagination } = action.payload;
        const alarms = data || [];

        state.total = pagination.total;
        state.alarms = alarms;
        state.loading = false;
        state.alarmsByMonth = {};
        state.alarmParams.page = Number(pagination.page);
      })

      .addCase(getAlarmByPagination.rejected, (state, action) => {
        const error = {
          error: true,
          errorOn: action?.payload?.errorOn
        };

        state.loading = false;
        state.error = error;
      })

      .addCase(getMoreAlarms.pending, state => {
        state.loading = true;
      })

      .addCase(getMoreAlarms.fulfilled, (state, action) => {
        const { data, ...pagination } = action.payload;
        const alarms = sortByDate([...state.alarms, ...data]);

        state.total = pagination.total;
        state.alarms = alarms;
        state.loading = false;
      })

      .addCase(getMoreAlarms.rejected, (state, action) => {
        const error = {
          error: true,
          errorOn: action?.payload?.errorOn
        };

        state.loading = false;
        state.error = error;
      })

      .addCase(getAlarmById.pending, state => {
        state.loading = true;
      })

      .addCase(getAlarmById.fulfilled, (state, action) => {
        state.activeAlarm.alarm = action.payload;
        state.loading = false;
      })

      .addCase(getAlarmById.rejected, (state, action) => {
        const error = {
          error: true,
          errorOn: action?.payload?.errorOn
        };

        state.loading = false;
        state.error = error;
      })

      .addCase(getAlarmsCount.pending, state => {
        state.loading = true;
      })

      .addCase(getAlarmsCount.fulfilled, (state, action) => {
        state.alarmsCount = action.payload;
        state.loading = false;
      })

      .addCase(getAlarmsCount.rejected, (state, action) => {
        const error = {
          error: true,
          errorOn: action?.payload?.errorOn
        };

        state.loading = false;
        state.error = error;
      });
  }
});

export const AlarmsActions = {
  ...slice.actions,
  getStreamAlarmDevices,
  getActiveAlarm,
  getLatestAlarms,
  getAlarmsByMonth,
  getAlarmByPagination,
  getMoreAlarms,
  getAlarmsCount,
  getAlarmById,
  getEmailNotificationSubscribersByOrg,
  updateEmailNotification
};

export const AlarmsReducer = slice.reducer;
