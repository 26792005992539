import { useCallback, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { getAlarmState } from "~/selectors/AlarmSelector";
import { AlarmsActions } from "~/store/slices/alarms";
import { getCurrentYearMonth, groupByDate } from "~/utils/dateTimeUtils";

import { useParseYearMonth } from "./use-parse-year-month";
import { useSettings } from "./use-settings";

export const useGetAlarmsByMonth = () => {
  const { getYearMonthKey, getYearMonth } = useParseYearMonth();
  const { deviceId, streamId: streamIdParam } = useParams();
  const { streams } = useSelector(state => state.cameraState);
  const { alarmsByMonth, loading } = useSelector(getAlarmState, shallowEqual);

  const streamId = streamIdParam || streams.byDeviceId[deviceId]?.id;
  const { timeZone } = useSettings();
  const dispatch = useDispatch();

  const groupedAlarmsByDay = useMemo(
    () => groupAlarmsByDay(alarmsByMonth, timeZone),
    [alarmsByMonth, timeZone]
  );

  const getAlarmsByMonth = useCallback(
    async (year, month) => {
      const dateStr = `${year}-${month}`;
      const containsAlarms = alarmsByMonth[dateStr];
      if (containsAlarms) return;

      await dispatch(
        AlarmsActions.getAlarmsByMonth({
          ...(streamId ? { streamId } : { deviceId }),
          year,
          month
        })
      );
    },

    [deviceId, dispatch, alarmsByMonth, streamId]
  );

  const getAlarmsForCurrentMonth = useCallback(() => {
    const { year, month } = getCurrentYearMonth();
    getAlarmsByMonth(year, month);
  }, [getAlarmsByMonth]);

  const getAlarmsMapByDay = day =>
    groupedAlarmsByDay[getYearMonthKey(day)] ?? new Map();

  const handleMonthChange = day => {
    const [year, month] = getYearMonth(day);
    getAlarmsByMonth(year, month);
  };

  return {
    loading,
    alarms: alarmsByMonth,
    getAlarmsByMonth,
    getAlarmsForCurrentMonth,
    getAlarmsMapByDay,
    groupedAlarmsByDay,
    handleMonthChange
  };
};

export const groupAlarmsByDay = (alarmsByMonth, timezone) => {
  const groupedAlarmsByDay = {};

  for (const [yearMonth, alarms] of Object.entries(alarmsByMonth)) {
    const groupedAlarms = groupByDate(alarms, timezone);
    groupedAlarmsByDay[yearMonth] = groupedAlarms;
  }

  return groupedAlarmsByDay;
};
